<template>
  <div class="goods_detail_page_wrap" v-if="data" >
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in data.sliderImage" :key="index" >
        <img :src="item" class="swiper_img" />
      </van-swipe-item>
    </van-swipe>
    <div class="goods_content" >
      <div class="goods_name" >{{ data.storeName }}</div>
      <div class="goods_desc" >{{ data.storeInfo }}</div>
      <div class="goods_price_wrap" >

        <div class="goods_price" >¥{{ data.price }}</div>

        <div class="count_wrap" v-if="false" >
          <img src="~@/components/ShoppingCart/img/2.png" class="count_icon" @click="changeCount(0)" />
          <div class="count_text" >{{ data.num }}</div>
          <img src="~@/components/ShoppingCart/img/3.png" class="count_icon" @click="changeCount(1)" />
        </div>

        <div class="cart_button" @click="changeCount(1)" >
          <img src="./img/2.png" class="cart_icon" />
        </div>

      </div>
    </div>
    <div class="goods_detail_wrap" >
      <div class="title" >商品详情</div>
      <div class="desc" v-html="data.description" >
      </div>
    </div>

    <div class="bottom_wrap" >
      <div class="bottom_left" >
        <van-badge :content="count" max="99">
          <img src="./img/3.png" class="cart_img" @click="$refs.cart.handleShow(cartData)" />
        </van-badge>
        <div class="line" ></div>
        <div class="price_wrap" >
          <div class="total_price" >
            <span>￥</span>
            {{ total }}
          </div>
          <div class="freight_money_text" >
            运费 {{ cartData.length > 0 ? freight : 0 }}元
          </div>
        </div>
      </div>
      <van-button class="buy_button" @click="toConfirm" >立即结算</van-button>
    </div>
    <shopping-cart ref="cart" @close="cartClose" />
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<style>
.desc img {
  width: 100%;
}
</style>
<script>
import {
  getGoods, getShoppingFeeVo
} from '@/api'
export default {
  name: 'goodsDetail',
  data() {
    return {
      storeProductId: this.$route.params.storeProductId,
      data: null,
      total: 0,
      freight: 0,
      cartData: !sessionStorage.getItem('cartData') ? [] : JSON.parse(sessionStorage.getItem('cartData')),
      config: {
        count: 0,
        minFee: 0,
        maxFee: 0
      },
      count: 0
    }
  },
  methods: {
    toConfirm() {
      if(this.cartData.length <= 0) {
        let data = []
        data.push(this.data)
        sessionStorage.setItem('cartData', JSON.stringify(data))
      }
      this.$router.push({
        name: 'confirmOrder'
      })
    },
    changeCount(type) {
      const newData = {...this.data}
      let num = newData.num
      switch (type) {
        case 0:
          if(num <= 1) {
            return false
          }
          newData.num = num - 1
          break
        case 1:
          newData.num = num + 1
          break
      }
      this.data = {...newData}
      let index = this.cartData.findIndex(item => { return item.storeProductId == this.data.storeProductId })
      if(index > -1) {
        this.cartData[index].num = this.data.num
      } else {
        this.cartData.push(this.data)
        this.cartData[0].num = 1
      }
      sessionStorage.setItem('cartData', JSON.stringify(this.cartData))
      this.getTotal()
    },
    cartClose(data) {
      this.cartData = data
      sessionStorage.setItem('cartData', JSON.stringify(data))
      let index = data.findIndex(item => { return item.storeProductId == this.data.storeProductId })
      console.log('index:', index)
      this.data.num = index > -1 ? this.cartData[index].num : 1
      this.getTotal()
    },
    getTotal() {
      const { cartData, config } = this
      let total = 0
      let count = 0
      cartData.map(item => {
        total += parseFloat(item.price) * item.num
        count += item.num
      })
      this.count = count
      this.total = total.toFixed(2)
      this.freight = count > config.count ? config.maxFee : config.minFee
    },
    getData() {
      const { storeProductId } = this
      getGoods({ storeProductId }).then(res => {
        if(res.success) {
          res.data.sliderImage = res.data.sliderImage.split(',')
          let index = this.cartData.findIndex(item => { return item.storeProductId == res.data.storeProductId })
          res.data.num = index > -1 ? this.cartData[index].num : 1
          this.data = res.data
          this.getShoppingFeeVo()
        }
      })
    },
    getShoppingFeeVo() {
      getShoppingFeeVo({}).then(res => {
        if(res.success) {
          this.config = res.data
          this.getTotal()
        }
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
